<template>
	<div>
		<slot />
	</div>
</template>

<style lang="scss" scoped>
.block-item {
	background: $primary-background-color;
	padding: 50px 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border: 10px solid #fff;
	}
}

.block-item:nth-child(even) {
	flex-direction: row-reverse;
}

@media (max-width: 960px) {
	.block-item,
	.block-item:nth-child(even) {
		flex-direction: column-reverse;
	}

	.block-item {
		padding: 40px 15px;
	}
}
</style>
