<template>
	<section v-if="reviews.length" class="main-content review-section">
		<div class="row">
			<div class="columns column12 align-center">
				<h4 class="subtitle">
					{{ subtitle }}
				</h4>
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="columns column12 review-slider align-center">
				<client-only>
					<carousel :per-page="1" :pagination-position="'bottom-overlay'" class="carousel">
						<slide v-for="review in reviews" :key="review.ID" class="review-slider-content">
							<p>{{ review.content }}</p>
							<div class="source">
								<strong>{{ review.firstName }}</strong>
								<span v-if="review.date">
									{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
								</span>
							</div>
						</slide>

						<template #addons>
							<pagination />
						</template>
					</carousel>
				</client-only>
			</div>
		</div>
	</section>
</template>

<script setup>
const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
	subtitle: { type: String, default: '' },
});

const { data: reviews } = await useWebsiteFetch('reviews', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});

const getReviewTotalAverage = () => {
	const sum = reviews.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviews.value.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviews.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});
</script>

<style lang="scss" scoped>
.review-slider {
	overflow: hidden;

	.review-slider-content {
		flex-direction: column;

		.source {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px 0;

			span,
			strong {
				margin: 0 15px 0 0;
			}
		}
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__viewport ol),
:deep(.carousel__pagination) {
	margin: 0;
}

:deep(.carousel__pagination-item) {
	margin: 10px 8px 15px;

	button {
		width: 11px;
		height: 11px;
		border: 1px solid #e4835f;
		border-radius: 50%;
		background: #fff;

		&.carousel__pagination-button--active {
			background: #e4835f;
		}

		&::after {
			display: none;
		}
	}
}
/* stylelint-enable selector-class-pattern */

@media (max-width: 900px) {
	.review-slider {
		padding: 50px 90px;
		overflow: hidden;

		&::before {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.review-slider {
		padding: 50px 70px;
		overflow: hidden;
	}
}

@media (max-width: 600px) {
	.review-slider {
		padding: 20px;
	}
}
</style>
